/*
This middkeware runs on both server and client context when using SSR.
We have to access redis differently in client and server context:
- in server context we have to use directly the redis client
- in client context we use the /api/redis route
*/

export default defineNuxtRouteMiddleware(async (to) => {
  const { stringToHash } = useHelpers();
  const key = "cmsPage:" + stringToHash(to.path);
  const fullKey =
    "frontend:" +
    (useRuntimeConfig().public.tenantId || process.env.NUXT_PUBLIC_TENANT_ID) +
    ":" +
    key;
  let foundInRedis = false;

  if (import.meta.server) {
    // console.log("loadCmsPage server context");
    const { getRedisClient } = await import("~/utils/redisClient");

    try {
      const client = await getRedisClient("loadCmsPage.js:1");
      const cachedPage = await client.get(fullKey);
      // console.log("cachedPage: ", cachedPage);
      if (cachedPage) {
        foundInRedis = true;
        // console.log("serving from redis, key: ", fullKey);
      }
    } catch (error) {
      // console.error("Redis interaction error:", error.message);
      // Handle gracefully in your application, e.g., proceed with fallback logic or return an appropriate response
    }
  } else {
    // console.log("loadCmsPage  client context");
    // client side, use the /api/redis
    const response = await $fetch("/api/redis", {
      method: "GET",
      params: { key },
    });
    if (response.success) {
      foundInRedis = true;
    }
    // console.log(response.value); // 'myValue'
  }

  if (!foundInRedis) {
    // console.log("loadCmsPage not found in redis! fetching from API");
    const {
      public: { apiBase, tenantId },
    } = useRuntimeConfig();

    const api = $fetch.create({
      baseURL: apiBase,
      headers: {
        "X-Tenant": tenantId, // Set the headers directly here
      },
      onRequest({ options }) {
        // Ensure headers are applied if not already present
        options.headers = options.headers || {};
        if (!options.headers["X-Tenant"]) {
          options.headers["X-Tenant"] = tenantId;
        }
      },
    });

    // If not cached, fetch and cache
    try {
      const cmsPage = await api("cmspage-from-url", {
        method: "POST",
        body: { url: to.path },
      });

      // Store it in Redis with TTL
      if (import.meta.server) {
        const { getRedisClient } = await import("~/utils/redisClient");
        try {
          /*
          console.log(
            "loadCmsPage server context storing in redis, key: ",
            fullKey,
          );
          */
          const client = await getRedisClient("loadCmsPage.js:2");
          await client.set(fullKey, JSON.stringify(cmsPage), {
            EX: 60, // TTL of 60 seconds
          });
        } catch (error) {
          // console.error("Redis interaction error:", error.message);
          // Handle gracefully in your application, e.g., proceed with fallback logic or return an appropriate response
        }
      } else {
        // console.log("loadCmsPage client context, store in redis, key: ", key);
        // const redisResult =
        await $fetch("/api/redis", {
          method: "POST",
          body: {
            key,
            value: JSON.stringify(cmsPage),
            ttl: 60,
          },
        });
        // console.log(redisResult);
      }
    } catch (error) {
      // console.log("error:", error);
      throw createError({
        statusCode: error?.response?.status || 500, // Use actual status code or default to 500
        statusMessage: error?.response?.statusText || "An error occurred",
      });
    }
  } else {
    // console.log("served from redis");
  }
});
